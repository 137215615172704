.headerTitle {
    border: 1px solid #FF503E; 
    height: 50px; 
    width: calc(100% - 100px);
    margin-top: 1rem; 
    border-radius: 15px; 
    display: flex; 
    padding: 5px 10px;
    color: #ccc;
}

.pay {
    width: 250px; 
    height: 40px; 
    border: 1px solid black;
    text-align: center;
    padding-top: 7px; 
    margin-bottom: 10px;
}

.spinner {
    height: 80px !important;
    width: 80px !important;
    color: #FF503E !important;
}