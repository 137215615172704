.sidebar {
    min-height: calc(100vh - 10px); 
    position: relative; 
    width: 320px;
    float: left;
}

.sidebar header p {
    margin: 0;
}

.list {
    overflow-y: scroll;
    max-height: 377px; /* for 5 items */
    margin: 8px 0 !important;
    padding: 0 !important;
}

.tab { 
    border-top: 2px solid rgba(0, 0, 0, 0.1);
    color: #ccc; 
    width: 100%;
    margin: 0;
    padding: 5px;
    cursor: pointer;
}

.tab:last-of-type{
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
}

.activeTab {
    border-top: 2px solid #FF503E;
    border-bottom: 2px solid #FF503E;
}

.tab:last-of-type.activeTab {
    border-bottom: 2px solid #FF503E;
}

.spinner {
    color: #FF503E !important;
}