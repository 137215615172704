.datascitab {
    border: 1px solid #FF503E;
    border-radius: 4px;
    height: 70px;
    padding: 10px 0px 0px 10px; 
    float: left;
    width: 48%; 
    margin-left: 15px;
}

.pay {
    width: 250px;
    height: 40px; 
    border: 1px solid black;
    text-align: center; 
    padding-top: 7px; 
    margin-bottom: 10px;
}