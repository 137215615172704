.headerTitle {
    border: 1px solid #FF503E;
    height: 50px;
    width: calc(100% - 100px);
    margin-top: 5px;
    margin-bottom: 5px;
    border-radius: 15px;
    display: flex;
    padding-top: 5px;
    padding-left: 10px;
    color: #ccc;
}