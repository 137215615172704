.pay {
    display: block;
    width: 250px; 
    margin: 0 auto;
    height: 40px; 
    border: 1px solid black;
    text-align: center;
    padding-top: 7px; 
    margin-bottom: 10px;
}
