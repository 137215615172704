form .form-control:focus {
    border-color: #FF503E !important;
    box-shadow: 0 0 0 0.2rem rgba(255,80,62,.25) !important;
}

.login-background {
    background-color: #FF503E;
    min-height: 100vh;
    min-width: 100vw;
    padding: 0 50px;
}

.login-wrapper { 
    height: 100vh;
    width: 100%;
    border-left: 2px solid #fff;
    border-right: 2px solid #fff;
    padding-top: 50px;
}

.login-wrapper-container {
    width: 100%;
    height: calc(100% - 50px);
    border-top: 2px solid #fff;
    border-bottom: 2px solid #fff;
}

.login-cta {
    background-color: #fff;
    padding: 60px 100px;
    border-radius: 20px;
}

.login-logo {
    max-width: 225px;
    height: auto;
    margin-bottom: 50px;
}

.login-cta form {
    min-width: 350px;
}

.login-cta .btn {
    min-width: 250px;
    text-align: center;
}

.login-error-message {
    font-size: 16px;
    color: #FF503E;
}

@media only screen and (max-width:992px) {
    .login-cta {
        padding: 50px 80px;
    }
}

@media only screen and (max-width:768px) {
    .login-logo {
        max-width: 175px;
    }

    .login-wrapper, .login-wrapper-container {
        border: none;
    }
}

@media only screen and (max-width:576px) {
    .login-cta {
        padding: 40px 60px;
    }

    .login-logo {
        max-width: 150px;
        margin-bottom: 35px;
    }

    .login-cta form {
        min-width: auto;
    }

    .login-cta .btn {
        min-width: auto;
    }
}