.accordion {
    border-bottom: 1px solid transparent;
}

.accordion:last-of-type {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
    outline: none;
}

.accordion__button[aria-expanded="true"] {
    border: 2px solid #FF503E;
}

.accordion__button:before{
    transition: all .4s; 
}