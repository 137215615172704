.btn-primary, .btn-primary:hover, .btn-primary:active, .btn-primary:focus {
    background-color:#FF503E !important;
    border-color:#FF503E !important;
    outline: none !important;
    box-shadow: none !important;
    color: #fff !important;
} 

a {
    color: #FF503E !important;
}

a:hover {
    color: #FF503E !important;
}

/* Add custom scrollbars */

/* width */
::-webkit-scrollbar {
    width: 6px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #ddd;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: #FF503E;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #FF503E;
}