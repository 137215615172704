.day {
    background-color: #ddd;
}

.td {
    border-right: 1px solid #dee2e6;
}

.td:last-of-type {
    border-bottom: 1px solid #dee2e6;
}