.logout {
    position: absolute;
    top: 8px;
    right: 13px;
    z-index: 123;
    cursor: pointer;
}

.exitApp {
    transform: translateX(10px);
    color: #FF503E;
    float: right;
    margin-top: 20px;
    margin-right: 15px;
}